import {
	cvl,
	eol,
	inventory,
	req_tracker,
	unauth,
	vdi,
	passwordReset,
	mobiledevices,
	pcversion,
	quarantine_portal,
	serviceaccounts,
	virtualasset,
} from "../../components/Dashboardv3/constants";

const getCurrentISODate = () => {
	return new Date().toISOString();
};

export const eventsInfo: any = {
	firstLoad: () => {
		return {
			additional_payload: "login + userinfo",
			event_id: 10041362,
			event_action: "load_view",
			event_type: "load",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["API Call"]: () => {
		return {
			additional_payload: "", //Object - pathname, baseurl, method
			event_id: 100413640,
			event_action: "network_call",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0, // Actual API response time if available
			status_code: 200, // Actual API Status code
			status_message: "", // Actual API Status message
		};
	},
	dashboardLoad: () => {
		return {
			additional_payload: "Dashboard page load",
			event_type: "load",
			event_id: 10041361,
			event_action: "page_view",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	redirectDashboardButton: () => {
		return {
			additional_payload: "", // add button info
			event_id: 10041366,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[cvl[0] + " info button"]: () => {
		return {
			additional_payload: cvl[0] + " info button",
			event_id: 100413614,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[quarantine_portal + " info button"]: () => {
		return {
			additional_payload: quarantine_portal + " info button",
			event_id: 100413647,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[req_tracker + " info button"]: () => {
		return {
			additional_payload: req_tracker + " info button",
			event_id: 100413613,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[inventory + " info button"]: () => {
		return {
			additional_payload: inventory + " info button",
			event_id: 100413615,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[eol + " info button"]: () => {
		return {
			additional_payload: eol + " info button",
			event_id: 100413617,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[unauth + " info button"]: () => {
		return {
			additional_payload: unauth + " info button",
			event_id: 100413616,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[vdi + " info button"]: () => {
		return {
			additional_payload: vdi + " info button",
			event_id: 100413618,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[passwordReset + " info button"]: () => {
		return {
			additional_payload: passwordReset + " info button",
			event_id: 100413642,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[mobiledevices + " info button"]: () => {
		return {
			additional_payload: mobiledevices + " info button",
			event_id: 100413643,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[pcversion + " info button"]: () => {
		return {
			additional_payload: pcversion + " info button",
			event_id: 100413651,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	[serviceaccounts + " info button"]: () => {
		//Added events for service accounts when info button is clicked
		return {
			additional_payload: serviceaccounts + " info button",
			event_id: 100413616,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[virtualasset + " info button"]: () => {
		//Added events for service accounts when info button is clicked
		return {
			additional_payload: virtualasset + " info button",
			event_id: 100413616,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	[cvl[0] + " toggleFavourite"]: () => {
		return {
			additional_payload: cvl[0] + " toggleFavourite",
			event_id: 10041368,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[req_tracker + " toggleFavourite"]: () => {
		return {
			additional_payload: req_tracker + " toggleFavourite",
			event_id: 10041367,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[inventory + " toggleFavourite"]: () => {
		return {
			additional_payload: inventory + " toggleFavourite",
			event_id: 100413612,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[eol + " toggleFavourite"]: () => {
		return {
			additional_payload: eol + " toggleFavourite",
			event_id: 100413610,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[serviceaccounts + " toggleFavourite"]: () => {
		//Added events for service accounts when toggleFavourite is clicked
		return {
			additional_payload: serviceaccounts + " toggleFavourite",
			event_id: 100413611,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[virtualasset + " toggleFavourite"]: () => {
		//Added events for service accounts when toggleFavourite is clicked
		return {
			additional_payload: virtualasset + " toggleFavourite",
			event_id: 100413611,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	[unauth + " toggleFavourite"]: () => {
		return {
			additional_payload: unauth + " toggleFavourite",
			event_id: 100413611,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[vdi + " toggleFavourite"]: () => {
		return {
			additional_payload: vdi + " toggleFavourite",
			event_id: 10041369,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[quarantine_portal + " toggleFavourite"]: () => {
		return {
			additional_payload: quarantine_portal + " toggleFavourite",
			event_id: 100413648,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[passwordReset + " toggleFavourite"]: () => {
		return {
			additional_payload: passwordReset + " toggleFavourite",
			event_id: 100413641,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[mobiledevices + " toggleFavourite"]: () => {
		return {
			additional_payload: mobiledevices + " toggleFavourite",
			event_id: 100413644,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[pcversion + " toggleFavourite"]: () => {
		return {
			additional_payload: pcversion + " toggleFavourite",
			event_id: 100413652,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle tab in " + quarantine_portal + " portal"]: () => {
		//DeviceTODO: remove this event
		return {
			additional_payload: "toggle tab in " + quarantine_portal + " portal",
			event_id: 100413654,
			event_action: "page_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["search Card Dashboard"]: () => {
		return {
			additional_payload: "", // Object - search: "search term", active: "active tab name"
			event_id: 100413635,
			event_action: "load_view",
			event_type: "load",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	toggleFavourite: () => {
		return {
			// Unused
			additional_payload: "",
			event_id: 10041364,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "",
		};
	},
	viewFavouriteApps: () => {
		return {
			additional_payload: "Favourite apps",
			event_id: 100413620,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	announcementClickEvent: () => {
		return {
			// TODO: mofify this event
			additional_payload: "",
			event_id: 100413633,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	announcementCarouselChange: () => {
		return {
			// TODO: mofify this event
			additional_payload: "",
			event_id: 100413634,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	viewMyOrgApps: () => {
		return {
			additional_payload: "Org apps",
			event_id: 100413632,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	viewMyActionsApps: () => {
		return {
			additional_payload: "My Action Apps",
			event_id: 100413631,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	viewAllApps: () => {
		return {
			additional_payload: "All apps",
			event_id: 100413619,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + inventory + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413626,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + vdi + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413625,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + req_tracker + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413626,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + cvl[0] + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413622,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + quarantine_portal + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413649,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + unauth + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413623,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + serviceaccounts + " tab"]: () => {
		//Added events for service accounts when tab is clicked
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413623,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + virtualasset + " tab"]: () => {
		//Added events for service accounts when tab is clicked
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413623,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	["toggle " + eol + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413624,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + mobiledevices + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413645,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["toggle " + pcversion + " tab"]: () => {
		return {
			additional_payload: "", // Send Tab name
			event_id: 100413653,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	["side nav " + req_tracker]: () => {
		return {
			additional_payload: "", // Send path and button name
			event_id: 100413629,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["side nav " + quarantine_portal]: () => {
		return {
			additional_payload: "", // Send path and button name
			event_id: 100413650,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["side nav " + cvl[0]]: () => {
		return {
			additional_payload: "", // Send path and button name
			event_id: 100413627,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["side nav " + inventory]: () => {
		return {
			additional_payload: "", // Send path and button name
			event_id: 100413628,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	[eol + " view more"]: () => {
		return {
			additional_payload: eol + " view more",
			event_id: 100413630,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["redirect dashboard banner"]: () => {
		return {
			additional_payload: "", // Object - cardName, path, external
			event_id: 100413636,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["dashboard drawer open"]: () => {
		return {
			additional_payload: "", // Object - cardName, active
			event_id: 100413637,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["Drag and Drop favourite apps"]: () => {
		return {
			additional_payload: "",
			event_id: 100413638,
			event_action: "load_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
	["user header dropdown"]: () => {
		return {
			additional_payload: "",
			event_id: 100413639,
			event_action: "click_view",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},

	["side nav " + mobiledevices]: () => {
		return {
			additional_payload: "", // Send path and button name
			event_id: 100413646,
			event_action: "page_redirection",
			event_type: "click",
			log_time: getCurrentISODate(),
			response_time: 0,
			status_code: 200,
			status_message: "Not a network call",
		};
	},
};
