import React, { useEffect, useState } from "react";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { fetchUserRecordsConfig } from "../../AssetTracker/API/api";
import LoadingSpinner from "../../Common/LoadingSpinner";
import CardError from "../../Dashboardv3/Cards/CardError";
import NoRecordFound from "../../Dashboardv3/CardTable/NoRecordFound";
import DDSAccordian from "../../Dashboardv3/Accordian/DDSAccordian";
import { req_tracker } from "../../Dashboardv3/constants";
import { Accordion } from "@dds/components";
import AccordianWrapper from "../../Dashboardv3/Accordian/Wrappers/AccordianWrapper";
import { ReactComponent as SearchIcon } from "../../../Assets/Search.svg"
import { PageTitle } from "../../AssetInventory/Admin/styles";
import useAxios from "../../../hooks/useAxios";
import { searchDeviceConfig } from "../API";
import AccordionItem from "./AccordionItem";

interface response {
    status: string;
    code: Number;
    message: string;
    records: any[]
}
const DeviceManagementLayout: React.FC = () => {
    const { isLoading: fetchingUsers, error: fetchUserError, sendRequest: fetchUsers } = useAxios();
    const [responseData, setResponseData] = useState<response | null>()

    useEffect(() => {
        const element = document.getElementById("unique-id-device-management");
        const accordionController = new Accordion(element, { independent: true });
        if (responseData && responseData?.records && responseData.records.length > 0)
            accordionController.expand && accordionController.expand(0);
    }, [responseData, fetchingUsers])

    const [inputValue, setInputValue] = useState("")
    const searchHandler = () => {
        console.log(inputValue);
        fetchUsers(searchDeviceConfig(inputValue), data => {
            console.log(data)
            setResponseData(data)
        })
    }
    return (
        <div className="dds__container-fluid dds__px-0">

            <PageTitle>Device Search</PageTitle>

            <div className="dds__d-flex dds__justify-content-between dds__flex-wrap dds__py-2 dds__px-4"
                style={{
                    backgroundColor: `var(--blue-600)`,
                    borderRadius: 10,
                    marginBottom: "20px"
                }}>
                <div
                    className={
                        "dds__d-flex dds__py-1 dds__flex-grow-1 dds__input-text__wrapper dds__input-text__wrapper--button dds__input-text__container--sm"}
                >
                    <input
                        style={{
                            width: '100%',
                            maxWidth: `300px`,
                        }}
                        onChange={e => setInputValue(e.target.value)}
                        onKeyPress={e => e.key === 'Enter' && searchHandler()}
                        type="text"
                        className="dds__input-text"
                        placeholder={"Search Hostname/ServiceTag"}
                    />

                    <button aria-label="Search"
                        style={{ minWidth: 50, backgroundColor: 'white' }}
                        className="dds__button dds__button--secondary dds__button--md"
                        type="button"
                        onClick={searchHandler}
                    >
                        <SearchIcon
                            style={{
                                margin: 0,
                            }}
                            className="dds__icon dds__button__icon--end"
                        />
                    </button>
                </div>
            </div>


            {fetchingUsers &&

                <div className="dds__mt-3 dds__mx-auto dds__text-center">
                    <LoadingSpinner label="Fetching" />
                </div>
            }

            {!fetchingUsers && responseData && responseData.records && responseData.records.length > 0 ?
                (<div role="region"
                    id="unique-id-device-management"
                    className="dds__accordion"
                    data-dds="accordion"
                    data-independent="true"
                    data-sr-collapse-all="3 items collapsed."
                    data-sr-expand-all="3 items expanded."
                    data-sr-expand-item="item expanded."
                    data-sr-collapse-item="item collapsed.">
                    <div className="dds__accordion__control">
                        <button
                            type="button"
                            className="dds__accordion__control__expand"
                            aria-disabled="false"
                            aria-label="expand all"
                        >
                            Expand All
                        </button>
                        <button
                            type="button"
                            className="dds__accordion__control__collapse"
                            aria-disabled="false"
                            aria-label="collapse all"
                        >
                            Collapse All
                        </button>
                    </div>
                    {responseData?.records.map((record: any, index) => (
                        <AccordionItem key={index} header={record.title} status={record.status} action={record.action} body={record.resultList} />
                    ))}
                </div>) :
                (!fetchingUsers && responseData ?
                    <NoRecordFound /> : (!fetchingUsers && <h4 className="dds_h4" style={{
                        borderRadius: 24,
                        color: 'black',
                        textAlign: "center"
                    }}> Search with Hostname/ServiceTag to get the device details </h4>))
            }
        </div >

    );
};

export default DeviceManagementLayout;