import React, { useEffect, useState } from "react";
import { HeaderLabel, HeaderLabelQuarantineportal, Main } from "./styles";
import BreadCrumb from "../components/Layout/BreadCrumb/BreadCrumb";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import { useUserInfo } from "../store/auth/UserInfoContext";
import { quarantine_portal } from "../components/Dashboardv3/constants";
import QuarantinePortalSideNavigation from "../components/Layout/SideNavbar/QuarantinePortalSideNavigation";

const QuarantinePortalNewLayout: React.FC<{
    children: JSX.Element;
    noNavbar: boolean | null;
    noFooter: boolean | null;
    crumbs: any[];
}> = ({ children, noNavbar, noFooter, crumbs }) => {
    const [userRole, setUserRole] = useState<{
        user: boolean,
        admin: boolean,
        superAdmin: boolean,
        leader: boolean
    }>({
        user: true,
        admin: false,
        superAdmin: false,
        leader: false
    });
    const user = useUserInfo();
    useEffect(() => {
        if (user) {
            const res = user?.getUserInfo();
            if (res.user_role && res.user_role[quarantine_portal]) {
                let role = res.user_role[quarantine_portal];
                setUserRole(role)
            }

        }
    }, [user]);
    return (
        <div className="dds__template--productivity">
            <Header />
            <QuarantinePortalSideNavigation userType={userRole} />
            <Main>
                <div style={{
                    position: 'relative',
                    width: '100%'
                }}>
                    <HeaderLabelQuarantineportal bkgColor={'#021757'} />
                </div>
                <div className='dds__container--compact'>
                    <BreadCrumb crumbs={crumbs} color="white" />
                    <div className="dds__pt-2"></div>
                    {children}
                </div>

            </Main >
            <Footer />
        </div >
    )
}

export default QuarantinePortalNewLayout;