import React, { useContext, useRef, useState } from 'react'
import DDSModal from '../../../Common/Modalv2/DDSModal';
import { EmployeeName, EmployeeEmail, EmployeeRole, CreateUser, EditUser, BlockDevicePermission, UnblockDevicePermission, EmployeeSearch, SearchDevicePermission } from './ModalForm/InputFields/InputFields';
import { createUpdateAdminsBlockedConfig } from '../API';
import { useCustomMutation } from '../../../../hooks/useCustomQuery';
import { ManageUserBlockedContext } from '../ManageUserBlockedContext';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';

const AdminModal: React.FC<{
	handleClose: () => void,
	mode: 'edit' | 'new',
	modalInstanceRef: React.MutableRefObject<any>,
	value?: {
		employeeName: string,
		email: string,
		role: string,
		permission: {
			create: boolean,
			edit: boolean,
			block: boolean,
			unblock: boolean,
			search: boolean,
		}
	},
	setShowSuccess?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ handleClose, mode, modalInstanceRef, value, setShowSuccess }) => {
	console.log("AdminModal", value);

	const userPermission = useUserInfo().getUserInfo().userRole.blockDevices;
	const [overflow, setOverflow] = useState<"auto" | "visible">("auto");

	const { refetch } = useContext(ManageUserBlockedContext);

	const handleCloseModifier = () => {
		handleClose()
	};

	const [formValue, setFormValue] = useState({
		employeeName: value?.employeeName || "",
		email: value?.email || "",
		role: value?.role || "",
		permission: value?.permission || {
			"create": false,
			"edit": false,
			"block": false,
			"unblock": false,
			"search": false,
		},
	});

	const saveSuccessHandler = () => {
		handleClose();
		setShowSuccess && setShowSuccess(true);
		refetch();
	}

	const {
		isLoading: isSaving,
		mutate: saveAPI,
	} = useCustomMutation(createUpdateAdminsBlockedConfig(mode), {
		onSuccess: saveSuccessHandler,
		// onError: error => {
		// 	console.error(error);
		// 	alert.show("Error Occurred while saving, please try again", {
		// 		type: "error",
		// 		timeout: 5000,
		// 	});
		// },
	});

	const updateDetails = () => {
		console.log("Updated details", formValue)
		saveAPI(formValue);

	};

	const submitDisabled = () => {
		if (formValue.employeeName === "" || formValue.role === "" || isSaving) {
			return true;
		}
		return false;
	};

	const [displayToggle, setDisplayToggle] = useState(value?.role || "");
	const [disableDropdown, setDisableDropdown] = useState(mode === "new");
	const [searchInput, setSearchInput] = useState(value?.email || "");
	const [disableSearch, setDisableSearch] = useState(mode === "edit");

	return (
		<DDSModal
			themeColor={'white'}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__modal--lg"}
			id={mode === 'new' ? 'Create Admin' : 'Edit Admin'} //employeeDetails!.ntid
			title={mode === 'new' ? 'Create Admin' : 'Edit Admin'}
			disposeOnClose={true}
			footerButtons={[{
				class: 'dds__button--secondary dds__button--md',
				text: 'Cancel',
				handleFooterButtonClick: handleCloseModifier
			}, {
				class: '',
				text: isSaving ? <LoadingSpinner size="sm" /> : 'Submit',
				handleFooterButtonClick: updateDetails,
				disabled: submitDisabled()
			}]}
			onClose={handleClose}
		>

			{/* <ModalForm mode={mode} overflow={overflow} handleClose={handleCloseModifier}
				employeeDetails={employeeDetails} /> */}
			<div style={{ minHeight: "260px" }}>
				<div className='dds__mb-4' style={{ display: 'grid', gridGap: '16px', gridTemplateColumns: 'repeat(auto-fit,minmax(200px,1fr))', width: '100%' }}>
					{/* <div className='dds__d-flex dds__mb-4' style={{gap:'16px',flexWrap:'wrap'}}> */}
					{/* <EmployeeName setFormValue={setFormValue} setDisableDropdown={setDisableDropdown}/> */}
					<EmployeeSearch setDisableDropdown={setDisableDropdown} mode={mode} searchInput={searchInput} setSearchInput={setSearchInput} disableSearch={disableSearch} setDisableSearch={setDisableSearch} setFormValue={setFormValue} />
					{/* <EmployeeEmail setFormValue={setFormValue} disableDropdown={disableDropdown}/> */}
					<EmployeeRole setFormValue={setFormValue} setDisplayToggle={setDisplayToggle} disableDropdown={disableDropdown} value={value} />
				</div>
				{displayToggle === "Admin" &&
					<>
						{<SearchDevicePermission setFormValue={setFormValue} value={value} />}
						{<BlockDevicePermission setFormValue={setFormValue} value={value} />}
						{<UnblockDevicePermission setFormValue={setFormValue} value={value} />}
					</>
				}
				{displayToggle === "SuperAdmin" &&
					<>
						{<CreateUser setFormValue={setFormValue} value={value} />}
						{<EditUser setFormValue={setFormValue} value={value} />}
						{<SearchDevicePermission setFormValue={setFormValue} value={value} />}
						{<BlockDevicePermission setFormValue={setFormValue} value={value} />}
						{<UnblockDevicePermission setFormValue={setFormValue} value={value} />}
					</>
				}
			</div>
		</DDSModal>
	);
}

export default AdminModal;

