import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { quarantine_portal } from "../../Dashboardv3/constants";
import { SideNavigation } from "./SideNavigation";

const QuarantinePortalSideNavigation: React.FC<{
	userType: {
		user: boolean;
		admin: boolean;
		superAdmin: boolean;
		leader: boolean;
	};
}> = ({ userType }) => {

	const user = useUserInfo().getUserInfo();

	const determineLinks = () => {
		const links = [
			{
				show: true,
				path: "/",
				name: "Home",
				iconClass: "dds__icon--home",
				cascadingLink: false,
			},
			{
				show: user.userRole.blockDevices.search,
				path: "/quarantine-portal/device-management",
				name: "Device Search",
				iconClass: "dds__icon--device-lock-screen",
				cascadingLink: false,
			},
			{
				show: userType.admin || userType.superAdmin,
				path: "/quarantine-portal/command-details",
				name: "Command Details",
				iconClass: "custom_admin_icon",
				cascadingLink: false,
			},
			{
				show: user.userRole.blockDevices.block || user.userRole.blockDevices.unblock,
				path: "/quarantine-portal",
				name: "Quarantine Devices",
				iconClass: "dds__icon--device-lock-screen",
				cascadingLink: true,
				children: [
					{
						show: user.userRole.blockDevices.block,
						path: "/block-devices",
						name: "Block Devices",
						iconClass: "dds__icon--device-lock-screen",
						cascadingLink: false,
					},
					{
						show: user.userRole.blockDevices.unblock,
						path: "/unblock-devices",
						name: "Unblock Devices",
						iconClass: "dds__icon--lock-open",
						cascadingLink: false,
					},
					{
						show: true,
						path: "/bulk-upload-logs",
						name: "Bulk Upload Logs",
						iconClass: "dds__icon--clipboard",
						cascadingLink: false,
					}
				]
			},
			{
				show: userType.superAdmin,
				path: "/quarantine-portal/manage-users",
				name: "Manage Users",
				iconClass: "dds__icon--gear-wrench",
				cascadingLink: false,
			},

		];
		// filter now show children out of links
		links.forEach(link => {
			if (link.children) {
				link.children = link.children.filter(child => child.show);
			}
		});

		return links.filter(link => link.show);
	};

	return (
		<SideNavigation portalName={quarantine_portal} links={determineLinks()} />
	);
};

export default QuarantinePortalSideNavigation;
