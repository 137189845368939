import { useEffect, useRef, useState } from "react";
import { Dropdown } from "@dds/components";
import DropdownComponent from "../../../../../Common/DropdownComponent";
import SwitchComponent from "../../../../../Common/SwitchComponent";
import { Switch } from "@dds/components";
import { useUserInfo } from "../../../../../../store/auth/UserInfoContext";
import useAxios from "../../../../../../hooks/useAxios";
// import { orgAutofillUserConfig } from "../../../../../AssetInventory/API";
import LoadingSpinner from "../../../../../Common/LoadingSpinner";
import { v4 } from "uuid";

const baseUrl = process.env.REACT_APP_INVENTORY_BASE_URL;
const orgAutofillUserConfig = (q: string) => {
	return {
		url: baseUrl + `/api/v1/searchUser?search=${q}`,
		method: "GET",
		Headers: {
			"Content-Type": "application/json",
		},
	};
};
//TODO: Error state updates are not instant so if the user types fast, error state might not get correctly updated, same with loading as well
export const EmployeeName = ({ setFormValue, setDisableDropdown }) => {
	const employeeNameInstanceRef = useRef<any>(null);
	const employeeNameDropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (employeeNameDropdownRef.current === null) return;
		employeeNameInstanceRef.current = new Dropdown(
			employeeNameDropdownRef.current, { closeOnScroll: false }
		);
		return () => {
			employeeNameInstanceRef.current.dispose()
		}
	}, [employeeNameDropdownRef]);

	const handleChange = (e) => {
		if (e.target.value === "") {
			setDisableDropdown(true);
			setFormValue(prevState => ({
				...prevState,
				employeeName: e.target.value
			}));
		}
		// else{
		//     console.log(e.target.value);

		//     setDisableDropdown(false);
		// }
	};

	const handleClick = (key) => {
		console.log(key);
		setFormValue(prevState => ({
			...prevState,
			admname: key
		}));
		setDisableDropdown(false);
	};

	return (
		<DropdownComponent
			elementRef={employeeNameDropdownRef}
			label="Employee Name"
			inputId={`empNameBlocked-single-dropdown-input${1}`}
			popupId={`empNameBlocked-single-dropdown-popup${1}`}
			inputName={`empNameBlocked-single-dropdown-name${1}`}
			selection="single"
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					<li className="dds__dropdown__item" role="none" key={"Admin"}>
						<button type="button"
							onClick={() => handleClick("Admin")}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"Admin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">Admin</span>
						</button>
					</li>
					<li className="dds__dropdown__item" role="none" key={"SuperAdmin"}>
						<button type="button"
							onClick={() => handleClick("SuperAdmin")}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"SuperAdmin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">SuperAdmin</span>
						</button>
					</li>
				</ul>}
			handleOnChange={handleChange}
		/>
	);
}

export const EmployeeEmail = ({ setFormValue, disableDropdown }) => {
	const employeeEmailInstanceRef = useRef<any>(null);
	const employeeEmailDropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (employeeEmailDropdownRef.current === null) return;
		employeeEmailInstanceRef.current = new Dropdown(
			employeeEmailDropdownRef.current, { closeOnScroll: false }
		);
		return () => {
			employeeEmailInstanceRef.current.dispose()
		}
	}, [employeeEmailDropdownRef]);

	const handleChange = (e) => {
		if (e.target.value === "") {
			setFormValue(prevState => ({
				...prevState,
				email: ""
			}));
		}
	};

	const handleClick = (key) => {
		setFormValue(prevState => ({
			...prevState,
			email: key
		}));
	};

	return (
		<DropdownComponent
			elementRef={employeeEmailDropdownRef}
			label="Employee Email ID"
			inputId={`empEmailBlocked-single-dropdown-input${1}`}
			popupId={`empEmailBlocked-single-dropdown-popup${1}`}
			inputName={`empEmailBlocked-single-dropdown-name${1}`}
			selection="single"
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					<li className="dds__dropdown__item" role="none" key={"Admin"}>
						<button type="button"
							onClick={() => handleClick("Admin")}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"Admin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">Admin</span>
						</button>
					</li>
					<li className="dds__dropdown__item" role="none" key={"SuperAdmin"}>
						<button type="button"
							onClick={() => handleClick("SuperAdmin")}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"SuperAdmin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">SuperAdmin</span>
						</button>
					</li>
				</ul>}
			// onInputClick={() => console.log("Clicked User Role")}
			inputDisabled={disableDropdown}
			handleOnChange={handleChange}
		/>
	);
}

export const EmployeeRole = ({ setFormValue, setDisplayToggle, disableDropdown, value }) => {
	const employeeRoleInstanceRef = useRef<any>(null);
	const employeeRoleDropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (employeeRoleDropdownRef.current === null) return;
		employeeRoleInstanceRef.current = new Dropdown(
			employeeRoleDropdownRef.current, { closeOnScroll: false }
		);
		return () => {
			employeeRoleInstanceRef.current.dispose()
		}
	}, [employeeRoleDropdownRef]);
	// const value = employeeRoleDropdownRef.current?.querySelector<HTMLInputElement>('#empRoleBlocked-single-dropdown-input1')?.value;

	const handleChange = (e) => {
		if (e.target.value === "") {
			setFormValue(prevState => ({
				...prevState,
				role: ""
			}));
		}
		// setFormValue(prevState => ({
		//     ...prevState,
		//     rolename: e.target.value
		// }));
		setDisplayToggle(e.target.value);
	}

	useEffect(() => {
		employeeRoleInstanceRef.current.selectOption(value?.role);
	}, [value?.role]);

	const handleClick = (key) => {
		setFormValue(prevState => ({
			...prevState,
			role: key,
		}));
		if (key === "Admin") {
			setFormValue(prevState => ({
				...prevState,
				permission: {
					...prevState.permission,
					create: false,
					edit: false
				}
			}));
		}
		setDisplayToggle(key);
	};

	// useEffect(() => {
	// 	if (employeeRoleDropdownRef.current !== null) {
	// 		const inputField = employeeRoleDropdownRef.current.querySelector('.dds__dropdown__input-field-hidden');
	// 		if (inputField instanceof HTMLInputElement) {
	// 			inputField.value = value?.rolename || '';
	// 		}
	// 	}
	// }, [value?.rolename]);

	return (
		<DropdownComponent
			elementRef={employeeRoleDropdownRef}
			label="Employee Role"
			inputId={`empRoleBlocked-single-dropdown-input${1}`}
			popupId={`empRoleBlocked-single-dropdown-popup${1}`}
			inputName={`empRoleBlocked-single-dropdown-name${1}`}
			selection="single"
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					<li className="dds__dropdown__item" role="none" key={"Admin"}>
						<button type="button"
							onClick={() => { handleClick("Admin") }}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"Admin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">Admin</span>
						</button>
					</li>
					<li className="dds__dropdown__item" role="none" key={"SuperAdmin"}>
						<button type="button"
							onClick={() => handleClick("SuperAdmin")}
							className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"SuperAdmin"} tabIndex={-1}>
							<span className="dds__dropdown__item-label">SuperAdmin</span>
						</button>
					</li>
				</ul>}
			handleOnChange={handleChange}
			inputDisabled={disableDropdown}
		/>
	);
}

export const CreateUser = ({ setFormValue, value }) => {
	const SwitchInstanceRefCreate = useRef<any>(null);
	const SwitchRefCreate = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (SwitchRefCreate.current === null) return;
		SwitchInstanceRefCreate.current = new Switch(
			SwitchRefCreate.current
		);
		if (value?.permission.create == true) {
			SwitchRefCreate.current.setAttribute("aria-checked", "true");
		}
		return () => {
			SwitchInstanceRefCreate.current.dispose()
		}
	}, [SwitchRefCreate]);

	const onClick = () => {
		const isChecked = SwitchRefCreate.current?.getAttribute("aria-checked") === "true";
		setFormValue(prevState => ({
			...prevState,
			permission: {
				...prevState.permission,
				create: isChecked
			}
		}));
	};

	return (
		<SwitchComponent SwitchRef={SwitchRefCreate} heading="Create User" onLabel="On" offLabel="Off" alignStart={true} onClick={onClick} ariaChecked={value?.permission.create} />
	);
}

export const EditUser = ({ setFormValue, value }) => {
	const SwitchInstanceRefEdit = useRef<any>(null);
	const SwitchRefEdit = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (SwitchRefEdit.current === null) return;
		SwitchInstanceRefEdit.current = new Switch(
			SwitchRefEdit.current
		);
		if (value?.permission.edit == true) {
			SwitchRefEdit.current.setAttribute("aria-checked", "true");
		}
		return () => {
			SwitchInstanceRefEdit.current.dispose()
		}
	}, [SwitchRefEdit]);
	const onClick = () => {
		const isChecked = SwitchRefEdit.current?.getAttribute("aria-checked") === "true";
		setFormValue(prevState => ({
			...prevState,
			permission: {
				...prevState.permission,
				edit: isChecked
			}
		}));
	};
	return (
		<SwitchComponent SwitchRef={SwitchRefEdit} heading="Edit User" onLabel="On" offLabel="Off" alignStart={true} onClick={onClick} ariaChecked={value?.permission.edit} />
	);
}

export const BlockDevicePermission = ({ setFormValue, value }) => {
	const SwitchInstanceRefBlocked = useRef<any>(null);
	const SwitchRefBlocked = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (SwitchRefBlocked.current === null) return;
		SwitchInstanceRefBlocked.current = new Switch(
			SwitchRefBlocked.current
		);
		if (value?.permission.block == true) {
			SwitchRefBlocked.current.setAttribute("aria-checked", "true");
		}
		return () => {
			SwitchInstanceRefBlocked.current.dispose()
		}
	}, [SwitchRefBlocked]);
	const onClick = () => {
		const isChecked = SwitchRefBlocked.current?.getAttribute("aria-checked") === "true";
		setFormValue(prevState => ({
			...prevState,
			permission: {
				...prevState.permission,
				block: isChecked
			}
		}));
	};
	return (
		<SwitchComponent SwitchRef={SwitchRefBlocked} heading="Block Device" onLabel="On" offLabel="Off" alignStart={true} onClick={onClick} ariaChecked={value?.permission.block} />
	);
}

export const UnblockDevicePermission = ({ setFormValue, value }) => {
	const SwitchInstanceRefUnBlocked = useRef<any>(null);
	const SwitchRefUnBlocked = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (SwitchRefUnBlocked.current === null) return;
		SwitchInstanceRefUnBlocked.current = new Switch(
			SwitchRefUnBlocked.current
		);
		if (value?.permission.unblock == true) {
			SwitchRefUnBlocked.current.setAttribute("aria-checked", "true");
		}
		return () => {
			SwitchInstanceRefUnBlocked.current.dispose()
		}
	}, [SwitchRefUnBlocked]);

	const onClick = () => {
		const isChecked = SwitchRefUnBlocked.current?.getAttribute("aria-checked") === "true";
		setFormValue(prevState => ({
			...prevState,
			permission: {
				...prevState.permission,
				unblock: isChecked
			}
		}));
	};
	return (
		<SwitchComponent SwitchRef={SwitchRefUnBlocked} heading="UnBlock Device" onLabel="On" offLabel="Off" alignStart={true} onClick={onClick} ariaChecked={value?.permission.unblock} />
	);
}

export const SearchDevicePermission = ({ setFormValue, value }) => {
	const SwitchInstanceRefSearch = useRef<any>(null);
	const SwitchRefSearch = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (SwitchRefSearch.current === null) return;
		SwitchInstanceRefSearch.current = new Switch(
			SwitchRefSearch.current
		);
		if (value?.permission.search == true) {
			SwitchRefSearch.current.setAttribute("aria-checked", "true");
		}
		return () => {
			SwitchInstanceRefSearch.current.dispose()
		}
	}, [SwitchRefSearch]);
	const onClick = () => {
		const isChecked = SwitchRefSearch.current?.getAttribute("aria-checked") === "true";
		setFormValue(prevState => ({
			...prevState,
			permission: {
				...prevState.permission,
				search: isChecked
			}
		}));
	};
	return (
		<SwitchComponent SwitchRef={SwitchRefSearch} heading="Device Search" onLabel="On" offLabel="Off" alignStart={true} onClick={onClick} ariaChecked={value?.permission.search} />
	);
}

export const EmployeeSearch: React.FC<{
	mode: 'new' | 'edit',
	searchInput: string,
	setSearchInput: React.Dispatch<React.SetStateAction<string>>
	disableSearch: boolean,
	setDisableSearch: React.Dispatch<React.SetStateAction<boolean>>
	setDisableDropdown: React.Dispatch<React.SetStateAction<boolean>>
	setFormValue: React.Dispatch<React.SetStateAction<any>>
	// setExtraEmployeeInformation: React.Dispatch<React.SetStateAction<extraEmployeeDetails>>
}> = ({
	mode,
	searchInput,
	setSearchInput,
	disableSearch,
	setDisableSearch,
	setDisableDropdown,
	setFormValue
}) => {
		let dropdownRef = useRef<any>();
		let dropdownInstanceRef = useRef<any>();

		const currentUser = useUserInfo();
		// useEffect(() => {
		// 	// Initialize Dropdown role-dropdown
		// 	if (initialize) {
		// 		console.log("Search dropdown init", dropdownRef.current)
		// 		dropdownInstanceRef = new Dropdown(dropdownRef.current);
		// 	}
		// 	return () => {
		// 		if (dropdownInstanceRef.current)
		// 			dropdownInstanceRef.current.dispose();
		// 	}
		// }, [])

		// useEffect(() => {
		// 	if (dropdownRef.current === null) return;
		// 	employeeNameInstanceRef.current = new Dropdown(
		// 		employeeNameDropdownRef.current,{closeOnScroll:false}
		// 	);
		// 	return () => {
		// 		employeeNameInstanceRef.current.dispose()
		// 	}
		// }, [employeeNameDropdownRef]);

		// const handleChange = (e) => {
		// 	if(e.target.value === ""){
		// 		setDisableDropdown(true);
		// 		setFormValue(prevState => ({
		// 			...prevState,
		// 			admname: e.target.value
		// 		}));
		// 	}
		// 	// else{
		// 	//     console.log(e.target.value);

		// 	//     setDisableDropdown(false);
		// 	// }
		// };

		// const handleClick = (key) => {
		// 	console.log(key);
		// 	setFormValue(prevState => ({
		// 		...prevState,
		// 		admname: key
		// 	}));
		// 	setDisableDropdown(false);
		// };

		// To autofill user info
		const { isLoading: fetchingUsers, error: fetchUserError, sendRequest: fetchUsers } = useAxios();

		// userList for autofill
		type user = { email: string, name: string, ntid: string, selected: boolean }
		const [userList, setUserList] = useState<user[]>([])

		// search input change handler
		const onEmailInputChange = (event) => {
			event.preventDefault();
			setSearchInput(event.target.value)
			if (event.target.value === "") {
				setDisableDropdown(true);
				setFormValue(prevState => ({
					...prevState,
					employeeName: event.target.value
				}));
			}
		}

		// fetch users whenever search input changes
		useEffect(() => {
			let users: user[] = []
			if (searchInput !== "" && mode === 'new') {
				let flag = 0
				userList.forEach(element => {
					console.log(element)
					if (element.selected === true) {
						flag = 1;
					}
				});
				if (flag === 0)
					fetchUsers(orgAutofillUserConfig(searchInput), data => {
						data.content.forEach(user => {
							if (user.email.toLowerCase() !== currentUser.getUserInfo().email.toLowerCase())
								users.push({ selected: false, email: user.email, name: user.userName, ntid: user.ntId })
						})
						setUserList(users)
						console.log(searchInput)
						console.log(users)
					})
			}
			else {
				setUserList([])
			}
		}, [searchInput])

		// set search input to the selected user and disable input
		useEffect(() => {
			userList.forEach(element => {
				console.log(element)
				if (element.selected === true) {
					setSearchInput(element.email)
					setDisableSearch(true)
				}
			});
		}, [userList])
		// Select user from autofill list
		const selectUserHandler = (email: string, name: string) => {
			console.log("e", email)
			setFormValue(prevState => ({
				...prevState,
				employeeName: name,
				email: email
			}));
			setDisableDropdown(false);
			setUserList(old =>
				old.map(e => {
					if (e.email === email) {
						// setExtraEmployeeInformation({
						// 	ntid: e.ntid,
						// 	admname: e.name,
						// 	accesstype: 'default',
						// })
						return {
							...e,
							selected: true
						}
					}
					return e
				}))
		}
		return (
			<div ref={dropdownRef} className="dds__dropdown" data-dds="" id={"user-dropdown" + v4()} data-selection="single">
				<div className="dds__dropdown__input-container">
					<label id="email-label" htmlFor="email-field">{mode === 'edit' ? 'Email' : 'Search by name *'}</label>
					<div className="dds__dropdown__input-wrapper">
						<input
							type="text"
							className="dds__dropdown__input-field"
							placeholder="Choose Employee Email"
							// minLength={2}
							maxLength={100}
							name="email-field"
							id="email-field"
							required
							aria-labelledby="email-field-label email-field-helper"
							onChange={onEmailInputChange}
							value={searchInput}
							disabled={disableSearch}
						/>
						<div id="email-feedback" className={`${userList.length === 0 && searchInput !== '' && !disableSearch && !fetchingUsers && fetchUserError?.message !== 'canceled' ? 'dds__d-block' : 'dds__d-none'}`} style={{
							opacity: 0.8,
							fontSize: '0.75rem',
							fontWeight: 400,
							letterSpacing: '0.005em',
							lineHeight: ' 1.25rem',
							color: '#bb2a33',
							marginBottom: '1rem',
						}}>No user found, please enter correct name.</div>
					</div>
					{<div id={`user-dropdown-popup`} className={`dds__dropdown__popup ${(userList.length > 0 && !disableSearch) || fetchingUsers ? 'dds__dropdown__popup--visible' : 'dds__dropdown__popup--hidden'}`} role="presentation" tabIndex={-1}
						style={{
							maxHeight: '15rem',
							position: 'initial'
						}}
					>
						<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
							{userList.length === 0 && <li className="dds__dropdown__item" role="none" key={"none"}>
								<button disabled className="dds__dropdown__item-option" role="option" data-selected="false" data-value={''} tabIndex={-1}>
									<span className="dds__dropdown__item-label">{''}</span>
								</button>
							</li>}
							{fetchingUsers &&

								<li className="dds__dropdown__item" role="none" key={"loading"}>
									<button disabled className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"loading"} tabIndex={-1}>
										<span className="dds__dropdown__item-label"><LoadingSpinner size='sm' /></span>
									</button>
								</li>
							}
							{!fetchingUsers &&
								userList?.map((e, index) => {
									return <li className="dds__dropdown__item" role="none" key={e.email}>
										<button onClick={() => selectUserHandler(e.email, e.name)} className="dds__dropdown__item-option" role="option" data-selected="false" data-value={e.email} tabIndex={-1}>
											<span className="dds__dropdown__item-label">{e.email}</span>
										</button>
									</li>
								})
							}
						</ul>
					</div>}
				</div>
			</div>
		)
	};
