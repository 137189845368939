import React from 'react';
import DropdownComponent from '../../../Common/DropdownComponent';

interface PlatformSourceDropdownProps {
    statusRef: React.MutableRefObject<any>; // replace with appropriate type
    platformList: string[];
}

const PlatformSourceDropdown: React.FC<PlatformSourceDropdownProps> = ({ statusRef, platformList }) => (
    <DropdownComponent
        elementRef={statusRef}
        PopupList={
            <ul
                className="dds__dropdown__list"
                role="listbox"
                tabIndex={-1}
            >
                {platformList.map((status, index) => (
                    <li key={index} className="dds__dropdown__item" role="none">
                        <button
                            type="button"
                            className="dds__dropdown__item-option"
                            role="option"
                            data-selected="false"
                            tabIndex={-1}
                        >
                            <span className="dds__dropdown__item-label">
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </span>
                        </button>
                    </li>
                ))}

            </ul>
        }
        label="Source"
    />
);

export default PlatformSourceDropdown;