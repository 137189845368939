import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";

const baseUrl = process.env.REACT_APP_BASE_URL;
const baseNewUrl = process.env.REACT_APP_BASE_URL_PCF;

const fetchStatusList = (): object => ({
	url: baseUrl + "/api/Fetch/v1/ActionTaken",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchPlatformSourceList = (): object => ({
	url: baseNewUrl + "/api/Fetch/v1/platformSource",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const useCommandStatusList = () => {
	return useCustomQuery([queryKeys.commandStatusList], fetchStatusList(), {
		select: data => {
			return data.actionTakenValueList;
		},
	});
};

export const platformSourceList = () => {
	return useCustomQuery(
		[queryKeys.platformSourceList],
		fetchPlatformSourceList(),
		{
			select: data => {
				return data.sourceList;
			},
		}
	);
};
