import { objectToQuery } from "../../utils/querycreator";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const newBaseUrl = process.env.REACT_APP_BASE_URL_PCF;
export const fetchBlockedDevicesConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	filters: {
		endDate: string;
		isDateRange: boolean;
		startDate: string;
		status: string;
	}
) => {
	let endpoint = "/api/Fetch/v2/FetchAllBlockedDevices";
	let headers = {
		"Content-Type": "application/json",
	};
	if (params.exportCsv === true) headers["responseType"] = "blob";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: headers,
		body: filters,
	};
};

export const fetchBulkUploadLogsConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	filters: {
		endDate: string;
		isDateRange: boolean;
		startDate: string;
		uploadType: string;
	}
) => {
	let endpoint = "/api/v1/BlockDevicesLogs";
	let headers = {
		"Content-Type": "application/json",
	};
	if (params.exportCsv === true) headers["responseType"] = "blob";
	delete params["exportCsv"];

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: headers,
		body: filters,
	};
};

export const fetchBlockDevicesTotalLogsConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string; // not allowed
	},
	fileid: string
) => {
	let endpoint = "/api/v1/BlockDevicesTotalLogs";
	let headers = {
		"Content-Type": "application/json",
	};
	console.log("params", params);
	if (params.exportCsv === true) headers["responseType"] = "blob";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params) + "&fileid=" + fileid,
		method: "GET",
		headers: headers,
	};
};

export const fetchBlockDevicesSuccessLogsConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	fileid: string
) => {
	let endpoint = "/api/v1/BlockDevicesSuccessLogs";
	let headers = {
		"Content-Type": "application/json",
	};
	if (params.exportCsv === true) headers["responseType"] = "blob";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params) + "&fileid=" + fileid,
		method: "GET",
		headers: headers,
	};
};

export const fetchBlockDevicesFailureLogsConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	fileid: string
) => {
	let endpoint = "/api/v1/BlockDevicesFailureLogs";
	let headers = {
		"Content-Type": "application/json",
	};
	console.log("params", params);
	if (params.exportCsv === true) headers["responseType"] = "blob";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params) + "&fileid=" + fileid,
		method: "GET",
		headers: headers,
	};
};

export const UploadExcelConfig = (
	action: "Block" | "Unblock"
	// body: File // this file would be passed through mutate function
) => {
	let endpoint = "/api/Add/v1/UploadExcel";
	let headers = {
		"Content-Type": "multipart/form-data",
		Accept: "*/*",
	};

	return {
		url: baseUrl + endpoint + "?Action=" + action,
		method: "POST",
		headers: headers,
		// body: body,
	};
};

export const downloadTemplateConfig = () => {
	let endpoint = "/api/v1/SampleQuarantineExcelSheet";
	const headers = {
		responseType: "blob",
	};

	return {
		url: baseUrl + endpoint,
		method: "GET",
		headers: headers,
	};
};

export const searchDeviceConfig = (device: string) => {
	return {
		url:
			newBaseUrl +
			`/api/Fetch/v1/Quarantine/searchDevice?searchValue=${device}`,
		method: "GET",
		Headers: {
			"Content-Type": "application/json",
		},
	};
};
