import ReactTooltip from 'react-tooltip';
import { DisplayUserRoleButton } from './ManageUserRoleButtons';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DefaultCell from '../../../Table/ReusableTableCells/DefaultCell';
import EditActionManageUser from '../ActionsManageUser/EditActionManageUser';
import DeleteActionManageUser from '../ActionsManageUser/DeleteActionManageUser';


export const columns: any[] = [
    {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: false,
        width: 64,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline" }}
                    data-for="emp_tip"
                    data-tip={value}
                >
                    <p className='dds__body-2'>{value}</p>

                </p>
                <ReactTooltip id="emp_tip" />
            </>
        ),
    },
    {
        Header: "Employee Email ID",
        accessor: "email",
        disableFilters: true,
        disableSortBy: false,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline" }}
                    data-for="empEmail_tip"
                    data-tip={value}
                >
                    <p className='dds__body-2'>{value}</p>

                </p>
                <ReactTooltip id="empEmail_tip" />
            </>
        ),
    },
    {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        disableSortBy: true,
        width: 320,
        Cell: ({ value, row }) => {
            const tagMap = {
                "logs": "View Logs",
                "view": "View Users",
                "create": "Create User",
                "edit": "Edit User",
                "block": "Block Device",
                "unblock": "Unblock Device",
                "search": "Device Search"
            }
            return (
                <div style={{ display: "flex", gap: "5px" }}>
                    <div className='dds__body-2' style={{ width: "100px" }} data-for="role_tip" data-tip={value}>{value}</div>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <DisplayUserRoleButton Name={tagMap["logs"]} />
                        {value === "SuperAdmin" && <DisplayUserRoleButton Name={tagMap["view"]} />}
                        {Object.keys(row.values.permission).map((key) => {
                            if (row.values.permission[key] === true) {
                                return <DisplayUserRoleButton Name={tagMap[key]} />;
                            }
                            return null;
                        })}
                    </div>
                    <ReactTooltip id="role_tip" />
                </div>
            );

        },
    },
    {
        Header: "ntid",
        accessor: "ntid",
        disableFilters: true,
        disableSortBy: true,
        show: false,
        Cell: DefaultCell
    },
    {
        Header: "permission",
        accessor: "permission",
        disableFilters: true,
        disableSortBy: true,
        show: false,
        Cell: DefaultCell
    },
    {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        width: 68,
        Cell: ({ value, row }) => {
            const user = useUserInfo().getUserInfo();
            const permissions = user.userRole.blockDevices;
            const userNTID = user.email;
            const sameUser = (userNTID + "").toLowerCase() == (row.values['email'] + "").toLowerCase()
            return (
                <div>
                    <EditActionManageUser disabled={permissions.edit == false} value={row.values} />
                    <DeleteActionManageUser disabled={permissions.edit == false && !sameUser} row={row} />
                </div>

            )
        }
    }
];
