import React from "react";

interface Record {
    [key: string]: any;
}

interface AccordionItemProps {
    header: string;
    status: string;
    action: string;
    body: Record[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({ header, status, action, body }) => {

    const toCamelCaseWithCapital = (str) => {
        const camelCaseStr = str
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
        return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
    };
    return (
        <div className={`dds__accordion__item dds__accordion__item--expanded`}>
            <h5 className="dds__accordion__heading">
                <button
                    type="button"
                    className="dds__accordion__button"
                    aria-expanded="false"
                >
                    {header}
                    {status && ` | ${status}`}
                    {action && ` | ${action}`}
                </button>
            </h5>

            <div id="content-basic-item1" className="dds__accordion__content" role="region" aria-labelledby="trigger-basic-item1">
                <div className="dds__accordion__body">
                    <div

                        className="dds__border dds__p-4"
                        style={{
                            backgroundColor: "var(--gray-100)",
                        }}
                    >
                        {body.length === 0 ? (
                            <p>No data Found</p>
                        ) : (
                            <ol style={{ listStyleType: 'none', paddingLeft: "6px" }}>
                                {body.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <li key={index}>
                                            {Object.entries(item).map(([key, value]) => {
                                                const camelCaseKey = toCamelCaseWithCapital(key);  // This line will help to print the key in camelcase
                                                const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);  // This line will help to print the first letter in caps 
                                                return (
                                                    <div key={key} style={{ display: 'flex', alignItems: 'center', padding: '5px 0' }}>
                                                        <span style={{ color: "#0063B8", textAlign: 'left', width: '30%', display: 'inline-block', overflowWrap: "anywhere", fontWeight: 'bold' }}>{capitalizedKey}
                                                        </span>
                                                        <span style={{ textAlign: 'left', width: '50%', display: 'inline-block' }}>{value}</span>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        {index < body.length - 1 && <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>}
                                    </React.Fragment>
                                ))}
                            </ol>)}
                    </div>
                </div>
            </div>

        </div>


    );
};

export default AccordionItem