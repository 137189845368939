import { useEffect, useState } from "react";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";
import Card from "../../Card/Card";
import { TriggerAction, createTrigger } from "../../Common/TriggerAction";
import { userTab } from "../../DashboardPage/useMetaAPI";
import { cardsTheme, virtualasset } from "../../constants";
import { useDashboardAPIs } from "../../DashboardPage/useDashboardAPIs";
import { useDashboardContext } from "../../../../store/dashboard/DashboardContext";
import { eventsInfo } from "../../../../store/analytics/eventsInfo";
import DDSDrawer from "../../Drawer/DDSDrawer";
import { useAnalytics } from "../../../../store/analytics/AnalyticsContext";


const VirtualAssetCard: React.FC<{
    favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
    favouriteStatus,
    description,
    tabsMetaData,
    actionRequired
}) => {
        const { addEventToQueue } = useAnalytics();
        const { isOrg } = useDashboardContext();
        const { active } = useDashboardContext();
        const { data: metaData } = useDashboardAPIs();
        const [message, setMessage] = useState("View Details")
        let redirectionURL = "https://apps.powerapps.com/play/e/9620fe81-6217-4ffe-959e-0f54bd9448e1/a/bf75079c-2133-4c57-893c-3da876588365?tenantId=945c199a-83a2-4e80-9f8c-5a91be5752dd&hint=55b7166c-3372-451a-b55d-0f865d81d555&sourcetime=1740983573155&source=portal"


        useEffect(() => {
            if (!isOrg) {
                if (metaData && metaData.data[virtualasset]) {
                    setMessage(metaData.data[virtualasset]);
                }
            }
        }, [metaData, isOrg]);

        const redirectPage = () => {
            logDrawerEvent();
            window.open(redirectionURL, '_blank');


        };

        const logDrawerEvent = () => {
            console.log("Drawer opened - virtualasset", virtualasset);
            addEventToQueue({
                ...eventsInfo["dashboard drawer open"](),
                additional_payload: {
                    virtualasset,
                    active
                },
            });
        };
        return <Card
            themeColor={cardsTheme[virtualasset].themeColor}
            name={virtualasset}
            ddsIconClass={cardsTheme[virtualasset].ddsIconClass}
            isFav={favouriteStatus}
            idName={virtualasset}
            description={description}
            isLoading={(message === "View Details")}
            actionRequired={actionRequired}
        >
            <button type="button" className="dds__button dds__button--tertiary" onClick={redirectPage}>
                {message}
            </button>
        </Card>

    }

export default VirtualAssetCard

